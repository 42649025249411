
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 500 500"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M105.8 144.3C105.8 64.6 170.4.1 250.1.1s144.3 64.6 144.3 144.2v37h21.9c18.7 0 35.4 6.8 48.7 20.1 13.3 13.3 20.1 29.9 20.1 48.7v181.2c0 18.7-6.8 35.4-20.1 48.7-13.3 13.3-29.9 20.1-48.7 20.1H83.9c-18.7 0-35.4-6.8-48.7-20.1-13.2-13.4-20-30.1-20-48.8V250c0-18.7 6.8-35.4 20.1-48.7s29.9-20.1 48.7-20.1h21.9l-.1-36.9zm46.9 37h194.8v-37c0-53.8-43.6-97.4-97.4-97.4s-97.4 43.6-97.4 97.4v37zm-68.8 46.8c-6.4 0-11.2 2-15.5 6.4-4.4 4.3-6.4 9.1-6.4 15.5v181.2c0 6.4 2 11.2 6.4 15.5 4.4 4.4 9.1 6.4 15.5 6.4h332.3c6.4 0 11.2-2 15.5-6.4 4.4-4.4 6.4-9.1 6.4-15.5V250c0-6.4-2-11.2-6.4-15.5-4.4-4.4-9.1-6.4-15.5-6.4H83.9z"}})])
          )
        }
      }
    